import NewsPage from './_news'
import { graphql } from 'gatsby'
import { React, Page } from 'lib'


function News({ data }) {
  return (
    <Page whiteBackground>
      <NewsPage posts={data.allMarkdownRemark.nodes}/>
    </Page>
  )
}

export default News

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          slug
          isBlog
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
