import { React, StyleSheet, View, Button, onUpdate, Image, useState, Link } from 'lib'
import { Settings, Theme } from 'app'
import { PageTitle } from 'components'
import SocialMedia, { InstagramDataProps } from 'actions/modules/InstagramPosts'

const Video = ({ videoSrcURL, videoTitle }) => (
  <View style={{ ...styles.video, ...styles.item }}>
    <iframe
      src={videoSrcURL+'autoplay=1'}
      title={videoTitle}
      allow='accelerometer; autoplay=1; encrypted-media; gyroscope; picture-in-picture'
      frameBorder='0'
      style={{ ...styles.video, ...styles.item }}
    />
  </View>
)

const InstagramPosts: React.FC = () => {
  const [posts, setPosts] = useState<InstagramDataProps[]>([])

  onUpdate(() => {
    SocialMedia.getInstagramPosts((data) => setPosts(data))
  }, [])

  if (!posts?.length) return null

  return (
    <>
      <PageTitle title='Our Insta Feed' style={{ marginTop: Theme.spacing(10) }}/>
      <View style={styles.newsGrid}>
        {posts.map(({ id, media_url, media_type, permalink, caption }) => {
          if (media_type === 'IMAGE' || media_type === 'CAROUSEL_ALBUM') {
            return (
              <Link key={id} openNewTab to={permalink} style={styles.item}>

                <View style={[styles.item, styles.imageWrapper]}>
                  <Image key={id} source={media_url} alt={caption} style={styles.image}/>
                </View>
              </Link>
            )
          } else {
            return (
              <Video
                key={id}
                videoSrcURL={media_url}
                videoTitle={caption}
              />
            )
          }
        })}
      </View>

      <Link href={Settings.INSTAGRAM_URL} openNewTab style={styles.linkButton}>
        <Button
          text='follow us'
          variant={'uppercase'}
          style={styles.linkButton}
          textStyle={styles.linkButtonText}
        />
      </Link>
    </>
  )
}

export default InstagramPosts

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    backgroundColor: Theme.colors.white,
    position: 'relative',
  },
  innerWrapper: {
    marginTop: Theme.spacing(5),
    marginBottom: Theme.spacing(16),
    display: 'flex',
    flexDirection: 'column',
    ...Theme.justifyCenter,
    ...Theme.alignCenter,
    [Theme.media.up('small')]: {
      gap: Theme.spacing(5),
    },
    [Theme.media.down('small')]: {
      gap: Theme.spacing(3),
    },
  },
  newsGrid: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gap: Theme.spacing(3),
    overflow: 'hidden',
    borderRadius: Theme.spacing(2.3),
    [Theme.media.up('small')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: 'repeat(2, 1fr)',
    },
    [Theme.media.down('small')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      gridTemplateRows: 'repeat(1, 1fr)',
      gap: Theme.spacing(3),
    },
  },
  item: {
    width: '100%',
    height: '100%',
    margin: '0 !important',
  },
  video: {
    [Theme.media.down('small')]: {
      minHeight: 423,
    },
  },
  imageWrapper: {
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    aspectRatio: '1',
  },
  linkButton: {
    alignSelf: 'center',
    width: 'fit-content',
    textDecorationLine: 'none !important',
  },
  linkButtonText: {
    color: Theme.colors.white,
  },
})
