import { React, SEO, CenterWrapper, StyleSheet } from 'lib'
import { Theme, HTMLStylesDark } from 'app'

import InstagramPosts from './InstagramPosts'
import Articles from './Articles'

function News(props) {
  return (
    <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper}>
      <SEO doNotTrack title={'News'} description={'News about Becon'}/>
      <Articles articles={props.posts}/>
      <InstagramPosts/>
    </CenterWrapper>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    ...Theme.flex,
    marginTop: Theme.spacing(20),
    marginBottom: Theme.spacing(5),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(12),
      marginBottom: Theme.spacing(5),
    },
  },
  innerWrapper: {
    ...HTMLStylesDark.plainText,
    gap: Theme.spacing(3),
    flexDirection: 'column',
  },
})

export default News
